/* eslint-disable eqeqeq */
import Reflux from 'reflux'
import Proxies from 'Components/proxies'
const { GET, POST } = Proxies
import _ from 'lodash'
import keyMirror from 'keymirror'
import moment from 'moment'
import 'moment/locale/da'

export const STATUS = keyMirror({
  CATALOGS_ERROR: null,
  FETCHING_PRODUCTS: null,
  PRODUCTS_ERROR: null,
  PRODUCTS_FETCHED: null,
  FETCHING_PRODUCT: null,
  FETCHING_PRODUCT_ERROR: null,
  TAKING_INPUT: null,
  REDIRECT: null,
  SAVING: null,
  PRODUCT_SUCCESS: null,
  PRODUCT_ERROR: null,
  PRODUCT_DELETE: null,
  PRODUCT_DELETE_ERROR: null,
  FETCHING_CATEGORY_ERROR: null,
  PRODUCT_TITLE_NONE: null,
  PRODUCT_CONTENT_NULL: null,
  VARIATION_CONTENT_NONE: null,
  VARIATION_EMAIL_NONE: null,
  VARIATION_MIN_STOCK_NONE: null,
  INVALID_EMAIL: null,
  COPY_PRODUCT_FAILED: null,
  COPY_PRODUCT_SUCCESS: null
})
export const ProductActions = Reflux.createActions([
  'getProducts',
  'setFilter',
  'applyPredefinedFilter',
  'updateFilter',
  'removeFilter',
  'addFilter',
  'getCatalogs',
  'showDetails',
  'clearToast',
  'createEmptyProduct',
  'getProductDetails',
  'updateProductField',
  'removeData',
  'addField',
  'getCategories',
  'getSubCategories',
  'toggleLinkedSubCategory',
  'addCategory',
  'updateData',
  'saveProduct',
  'clearFormToast',
  'deleteProduct',
  'addAttachment',
  'fileUploaded',
  'videoFileUploaded',
  'deleteImage',
  'deleteVideo',
  'getCatalogProducts',
  'preserveSearch',
  'getSuppliers',
  'copyProduct',
  'getColors',
  'getLanguages',
  'addTranslation',
  'removeTranslation'
])

export class ProductStore extends Reflux.Store {
  constructor(props) {
    super(props)
    this.listenables = ProductActions
    this.state = {
      status: null,
      products: [],
      subCategories: [],
      languages: [],
      predefinedFilters: [
        { filterID: 101, column: 'Product.productStatus', operator: 'EQUAL', value: 'AVAILABLE', selected: false, name: 'Available' },
        { filterID: 102, column: 'Product.productStatus', operator: 'EQUAL', value: 'UNAVAILABLE', selected: false, name: 'UnAvailable' },
        { filterID: 106, column: 'Catalog.moduleName', operator: 'EQUAL', value: 'Backlog (LBH)', selected: false, name: 'Backlog' }
      ],
      filters: [],
      catalogs: [],
      product: {
        elementTitle: '',
        elementContent: '',
        productPrice: '',
        retailPrice: '',
        productWeightInGram: '',
        productPriceAdjust: '',
        priceWithMarkup: '',
        productStatus: '',
        timeStart: moment().format('YYYY-MM-DD'),
        stockAvailableDate: null,
        productStockLimit: 0,
        timeEnd: null,
        categories: [],
        linkedSubCategoryIDs: [],
        catalogs: [],
        variations: [],
        products: [],
        attachments: [],
        video: '',
        tags: [],
        manufacturer: '',
        SupplierID: '',
        dropshipFreightPrice: '',
        enableDropship: 0,
        colli: 1,
        currency: '',
        currencyPrice: '',
        translations: []
      },
      brands: [],
      appliedFilter: false,
      categoryProducts: [],
      suppliers: [],
      colors: [],
      filtertext: '',
      categoryTags: []
    }
  }

  getProducts() {
    this.setState({ status: STATUS.FETCHING_PRODUCTS })
    let filters = this.state.filters
      .filter(e => {
        return e.column != '' && e.value != ''
      })
      .map(e => {
        return { column: e.column, operator: e.operator ? e.operator : 'LIKE', value: e.value }
      })
    let filterList = []
    const columnList = _.groupBy(filters, 'column')
    for (var i in columnList) {
      let column = i
      const OperatorList = _.groupBy(columnList[i], 'operator')
      for (var j in OperatorList) {
        let values = []
        OperatorList[j].map(function(item) {
          values.push(item.value)
        })
        filterList.push({ column: column, operator: j, values })
      }
    }
    GET('products/pool', { filters: filterList }).then(responseJSON => {
      let nextState = {
        products: []
      }
      if (responseJSON) {
        if (responseJSON.error) {
          nextState.status = STATUS.PRODUCTS_ERROR
        } else {
          responseJSON.map(item => {
            item.variations && item.variations.length > 3 ? (item.variationReadLess = true) : (item.variationReadMore = true)
          })
          nextState.products = responseJSON
          nextState.status = STATUS.PRODUCTS_FETCHED
        }
      }
      this.setState(nextState)
    })
  }
  applyPredefinedFilter(index) {
    let filters = this.state.filters
    let predefinedFilters = this.state.predefinedFilters
    let predefinedFilter = predefinedFilters[index]
    predefinedFilter.selected = !predefinedFilter.selected
    if (predefinedFilter.selected) {
      filters.push({ ...predefinedFilter })
    } else {
      filters = filters.filter(e => {
        return !(e.column == predefinedFilter.column && e.value == predefinedFilter.value)
      })
    }

    this.setState({ filters, predefinedFilters })
    this.getProducts()
  }
  addFilter(data = {}) {
    let filters = this.state.filters
    let lastFilterID = -1
    if (filters.length > 0) {
      let filterIDs = filters.map(e => {
        return e.filterID
      })
      lastFilterID = filterIDs.reduce((a, b) => {
        return Math.max(a, b)
      })
    }
    let filter = { filterID: lastFilterID + 1 }
    if (!data.column) {
      data = { column: '', operator: 'EQUAL', value: '' }
    }
    Object.assign(filter, data)
    filters.push(filter)
    this.setState({ filters: filters })
  }
  removeFilter(filterID) {
    let filters = this.state.filters
    let predefinedFilters = this.state.predefinedFilters
    let filter = filters[filterID]
    filters.splice(filterID, 1)
    let record = _.filter(predefinedFilters, o => filter.value === o.value)
    if (record.length) record[0].selected = false
    this.setState({ filters, predefinedFilters })
  }
  updateFilter(editFilter, field) {
    let filterID = editFilter.filterID
    let fieldName = field.target.name
    let fieldValue = field.target.value
    let filters = this.state.filters
    for (let filter of filters) {
      if (filter.filterID == filterID) {
        filter[fieldName] = fieldValue
        if (fieldName == 'column') {
          filter.value = ''
        }
      }
    }
    this.setState({ filters })
    if (fieldName === 'column' || fieldName === 'value') {
      this.resetPredefinedFilters()
    }
  }
  getCatalogs(CatalogID) {
    GET('schemas/catalogs').then(responseJSON => {
      let nextState = {
        catalogs: []
      }
      if (responseJSON) {
        if (responseJSON.error) {
          nextState.status = STATUS.CATALOGS_ERROR
        } else {
          nextState.catalogs = responseJSON
          if (CatalogID) {
            const record = _.filter(responseJSON, o => o.CatalogID === Number(CatalogID))
            if (record.length) {
              this.getCatalogProducts(record[0].moduleName)
            }
          }
        }
      }
      this.setState(nextState)
    })
  }
  showDetails(e) {
    let products = this.state.products
    products.map(item => {
      if (item.ProductID === e.ProductID) {
        item.variationReadMore = !item.variationReadMore
        item.variationReadLess = !item.variationReadLess
      }
      return item
    })
    this.setState({ products })
  }
  clearToast() {
    this.setState({ status: null })
  }

  /*Create Product*/

  createEmptyProduct() {
    this.setState({
      product: {
        elementTitle: '',
        elementContent: '',
        productPrice: '',
        retailPrice: '',
        productPriceAdjust: '',
        priceWithMarkup: '',
        productStatus: 'AVAILABLE',
        productWeightInGram: '',
        timeStart: moment().format('YYYY-MM-DD'),
        timeEnd: null,
        stockAvailableDate: null,
        productStockLimit: 0,
        minCount: 1,
        categories: [],
        catalogs: [
          {
            CatalogID: '',
            productPrice: '',
            productPriceAdjust: '',
            sticky: false,
            stickyDuration: 1,
            enableDiscount: false,
            discountPrice: '',
            basisPrice: ''
          }
        ],
        variations: [
          {
            variationTitle: '',
            variationUID: '',
            variationStock: null,
            variationStockable: false,
            externalStock: false,
            attachments: [{ ImageID: '' }],
            subscriptions: [
              {
                triggerValue: '',
                subscriberContactValue: ''
              }
            ],
            variationSize: '',
            variationColor: '',
            allowPreorder: false,
            preorderStock: null,
            poolOrder: 0
          }
        ],
        products: [{ CategoryID: '' }],
        attachments: [],
        tags: [{ CategoryID: '' }],
        manufacturer: '',
        SupplierID: '',
        dropshipFreightPrice: '',
        enabledropship: 0,
        translations: [
          {
            LanguageID: null,
            elementTitle: '',
            elementContent: ''
          }
        ]
      }
    })
  }

  getLanguages(BrandID) {
    GET('brands/brand-languages', { BrandIDs: [BrandID] }).then(responseJSON => {
      if (responseJSON.error) {
        // console.log(responseJSON.error)
      } else {
        this.setState({ languages: responseJSON })
      }
    })
  }

  removeTranslation = i => {
    let translations = this.state.product.translations
    if (translations[i].ProductTranslationID) {
      translations[i].removed = 1
      if (translations.length === 1) {
        translations.push({
          LanguageID: null,
          elementTitle: '',
          elementContent: ''
        })
      }
    } else {
      translations.splice(i, 1)
      if (!translations.length) {
        translations.push({
          LanguageID: null,
          elementTitle: '',
          elementContent: ''
        })
      }
    }
    this.setState({ product: { ...this.state.product, translations: translations } })
  }

  addTranslation = () => {
    let translations = this.state.product.translations
    translations.push({ elementTitle: '', LanguageID: null, elementContent: '' })
    this.setState({ product: { ...this.state.product, translations: translations } })
  }

  getProductDetails(ProductID = null) {
    if (ProductID) {
      this.setState({ status: STATUS.FETCHING_PRODUCT })
      GET('products/fetch', { ProductID: ProductID }).then(responseJSON => {
        let nextState = {
          product: {}
        }
        if (responseJSON) {
          if (responseJSON.error) {
            nextState.status = STATUS.FETCHING_PRODUCT_ERROR
          } else {
            nextState.status = STATUS.TAKING_INPUT
            responseJSON.products = []
            responseJSON.suppliers = []
            responseJSON.tags = []
            if (responseJSON.translations && !responseJSON.translations.length) {
              responseJSON.translations = [
                {
                  LanguageID: null,
                  elementTitle: '',
                  elementContent: ''
                }
              ]
            }
            if (responseJSON.catalogProducts && responseJSON.catalogProducts.length) {
              responseJSON.catalogProducts.map(item => {
                item.sticky ? item.sticky : (item.sticky = false)
                item.stickyDuration = 1
                return item
              })
              responseJSON.catalogs = responseJSON.catalogProducts
            }
            if (responseJSON.variations.length) {
              responseJSON.variations.map(item => {
                item.attachments && item.attachments.length ? item.attachments : (item.attachments = [{ ImageID: '' }])
                item.subscriptions && !!item.subscriptions.length
                  ? item.subscriptions
                  : (item.subscriptions = [
                      {
                        triggerValue: '',
                        subscriberContactValue: ''
                      }
                    ])
                return item
              })
            }
            if (responseJSON.attachments.length) {
              responseJSON.attachments.map(item => (item.featureImage === 1 ? (item.featureImage = true) : (item.featureImage = false)))
            }
            if (responseJSON.categories && responseJSON.categories.length) {
              responseJSON.categories.map(item => {
                if (item.categoryTaxonomy === 'PRODUCT') {
                  responseJSON.products.push(item)
                }
                if (item.categoryTaxonomy === 'TAG') {
                  responseJSON.tags.push({ CategoryID: item.CategoryID })
                }
              })
            }
            responseJSON.manufacturer = responseJSON.manufacturer ? responseJSON.manufacturer : ''
            responseJSON.timeEnd = responseJSON.timeEnd ? moment(responseJSON.timeEnd).format('YYYY-MM-DD') : null
            responseJSON.timeStart = moment(responseJSON.timeStart).format('YYYY-MM-DD')
            responseJSON.stockAvailableDate = moment(responseJSON.stockAvailableDate).format('YYYY-MM-DD')
            responseJSON.priceWithMarkup = this.calculateMarkupPrice(responseJSON.productPrice, responseJSON.productPriceAdjust)
            nextState.product = responseJSON
          }
        }
        this.setState(nextState)
      })
    }
  }

  calculateMarkupPrice(price, markup) {
    if (!markup || !price) return 0
    const markupPrice = !markup || !price ? '' : price * (1 + markup / 100)
    const profitPrice = markupPrice - price
    const profitPercentage = ((profitPrice / markupPrice) * 100).toFixed(2)
    return profitPercentage
  }

  updateProductField(field) {
    let product = this.state.product
    let name = field.target.name
    let value = field.target.value
    if (name === 'timeStart') {
      value = moment(value).format('YYYY-MM-DD')
    }
    if (name === 'timeEnd') {
      if (value) {
        value = moment(value).format('YYYY-MM-DD')
      } else {
        value = null
      }
    }
    if (name === 'stockAvailableDate') {
      if (value) {
        value = moment(value).format('YYYY-MM-DD')
      } else {
        value = null
      }
    }
    if (name === 'productWeightInGram' || name === 'SupplierID') {
      value = value ? Number(value) : ''
    }
    if (name === 'colli') {
      value = value ? Number(value) : 0
    }
    if (name === 'productPrice') {
      product['priceWithMarkup'] = this.calculateMarkupPrice(value, this.state.product.productPriceAdjust)
    }
    if (name === 'productPriceAdjust') {
      product['priceWithMarkup'] = this.calculateMarkupPrice(this.state.product.productPrice, value)
    }
    if (name === 'enableDropship') {
      value = field.target.checked
      if (!value) {
        product['dropshipFreightPrice'] = ''
      }
    }
    if (name === 'taxExempted') {
      value = field.target.checked
    }

    product[name] = value
    this.setState({ product })
  }
  removeData(index, type) {
    let product = this.state.product
    product[type].splice(index, 1)
    this.setState({ product })
  }
  addField(data, type) {
    let product = this.state.product
    product[type].push(data)
    this.setState({ product })
  }
  getCategories() {
    GET('categories/pool').then(responseJSON => {
      let nextState = { brands: [], categoryProducts: [], categoryTags: [] }
      if (responseJSON.error) {
        nextState.status = STATUS.FETCHING_CATEGORY_ERROR
      } else {
        responseJSON.map(item => {
          if (item.categoryTaxonomy === 'MANUFACTURER' && item.categoryName !== '') {
            nextState.brands.push(item)
          }
          if (item.categoryTaxonomy === 'PRODUCT' && item.categoryName !== '') {
            nextState.categoryProducts.push(item)
          }
          if (item.categoryTaxonomy === 'TAG' && item.categoryName !== '') {
            nextState.categoryTags.push(item)
          }
        })
        nextState.categories = responseJSON
      }
      this.setState(nextState)
    })
  }
  getSubCategories() {
    GET('SubCategories/adminPool').then(responseJSON => {
      let nextState = {
        subCategories: []
      }
      if (!responseJSON.error) {
        nextState.subCategories = responseJSON
        // if(this.state.product.linkedSubCategoryIDs && this.state.product.linkedSubCategoryIDs.length) {
        //   let categories = this.state.product.products
        //   categories = categories.map(c => {
        //     const linkedSubCategoryIDs = responseJSON.filter(sc => Number(sc.CategoryID) === Number(c)).map(sc => sc.SubCategoryID).map(Number)
        //     return {...c, linkedSubCategoryIDs}
        //   })
        // }
      }
      this.setState(nextState)
    })
  }
  getSuppliers() {
    GET('suppliers/pool').then(responseJSON => {
      let nextState = { suppliers: [] }
      if (responseJSON.error) {
        nextState.status = STATUS.FETCHING_CATEGORY_ERROR
      } else {
        nextState.suppliers = responseJSON
      }
      this.setState(nextState)
    })
  }
  getColors() {
    GET('colors/pool').then(responseJSON => {
      let nextState = { colors: [] }
      if (responseJSON.error) {
        nextState.status = STATUS.FETCHING_CATEGORY_ERROR
      } else {
        nextState.colors = responseJSON
      }
      this.setState(nextState)
    })
  }

  toggleLinkedSubCategory(subCategory, index) {
    if (!subCategory || !subCategory.SubCategoryID) return
    const product = this.state.product
    const categoryField = product.products[index]
    let allSubCategoryIDs = this.state.product.linkedSubCategoryIDs || []
    let linkedSubCategoryIDs = categoryField.linkedSubCategoryIDs || []

    // update current category form field
    if (linkedSubCategoryIDs.includes(subCategory.SubCategoryID)) {
      linkedSubCategoryIDs = linkedSubCategoryIDs.filter(scid => scid !== subCategory.SubCategoryID)
    } else {
      linkedSubCategoryIDs.push(subCategory.SubCategoryID)
    }
    product.products[index].linkedSubCategoryIDs = linkedSubCategoryIDs

    // update all linkedSubCategoryIDs
    if (allSubCategoryIDs.includes(subCategory.SubCategoryID)) {
      allSubCategoryIDs = allSubCategoryIDs.filter(scid => scid !== subCategory.SubCategoryID)
    } else {
      allSubCategoryIDs.push(subCategory.SubCategoryID)
    }
    product.linkedSubCategoryIDs = [...new Set(allSubCategoryIDs)]
    this.setState({ product })
  }

  updateData(field, index, type) {
    let fieldName = field.target.name
    let fieldValue = field.target.value
    let product = this.state.product
    let column = product[type][index]
    if (fieldName === 'attachments' && type === 'variations') {
      fieldValue = [{ ImageID: fieldValue }]
    }
    if (fieldName === 'poolOrder' && type === 'variations') {
      fieldValue = Number(fieldValue)
    }
    if (fieldName === 'LanguageID' && type === 'translations') {
      fieldValue = Number(fieldValue)
    }
    if (
      fieldName === 'variationStockable' ||
      fieldName === 'sticky' ||
      fieldName === 'featureImage' ||
      fieldName === 'enableDiscount' ||
      fieldName === 'setProductNew' ||
      fieldName === 'isNotNew' ||
      fieldName === 'allowPreorder' ||
      fieldName === 'externalStock' ||
      fieldName === 'stockOverride'
    ) {
      fieldValue = field.target.checked
    }

    if (fieldName === 'variationStock' && !Number.isInteger(parseInt(fieldValue))) {
      fieldValue = null
    }
    if (fieldName === 'enableDiscount') {
      if (!fieldValue) {
        column['basisPrice'] = ''
        column['discountPrice'] = ''
      }
    }
    if (fieldName === 'isNotNew') {
      if (fieldValue) {
        column['setProductNew'] = false
      }
    }
    if (fieldName === 'setProductNew') {
      if (fieldValue) {
        column['isNotNew'] = false
      }
    }
    if (fieldName === 'stickyDuration') {
      column.stickyEndtime = moment().add(fieldValue, 'hours')
    }
    if (!column[fieldName]) {
      column[fieldName] = ''
    }

    column[fieldName] = fieldValue
    if (type === 'variations' && (fieldName === 'subscriberContactValue' || fieldName === 'triggerValue')) {
      if (!column.subscriptions || (column.subscriptions && !column.subscriptions.length)) {
        column.subscriptions = [{ triggerValue: '', subscriberContactValue: '' }]
      }
      column['subscriptions'][0][fieldName] = fieldValue
      if (fieldName === 'triggerValue' && !fieldValue) {
        column['subscriptions'][0]['subscriberContactValue'] = ''
      }
      column['subscriberContactValueError'] && delete column['subscriberContactValueError']
    }
    this.setState({ product })
  }
  saveProduct() {
    let product = this.state.product
    const emptyVar = product.variations.filter(variation => !variation.variationUID)
    const EmailRegExp = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
    let hasError = {}
    product.translations =
      product.translations && product.translations.length
        ? product.translations.filter(translation => translation.elementTitle !== '' || translation.LanguageID !== null)
        : []
    if (!product) hasError.productError = true
    if (!product.catalogs.length > 0) hasError.catalogError = true
    if (product.elementTitle || product.elementContent || !emptyVar.length) {
      product.variations = product.variations.map(variation => {
        if (variation.variationUID && variation.subscriptions && !!variation.subscriptions.length) {
          variation.subscriptions = variation.subscriptions.filter(subscription => {
            if (subscription.triggerValue) {
              if (!subscription.subscriberContactValue) {
                variation['subscriberContactValueError'] = true
                hasError.subscriberContactValueError = true
              }
              if (subscription.subscriberContactValue) {
                if (!EmailRegExp.test(subscription.subscriberContactValue)) {
                  hasError.InvalidsubscriberContactValue = true
                  variation['subscriberContactValueError'] = true
                }
              }
              return subscription
            }
          })
        }
        return variation
      })
    }
    if (!product.elementTitle || !product.elementContent || emptyVar.length || Object.keys(hasError).length > 0) {
      if (!product.elementTitle) {
        this.setState({ status: STATUS.PRODUCT_TITLE_NONE })
      } else if (!product.elementContent) {
        this.setState({ status: STATUS.PRODUCT_CONTENT_NONE })
      } else if (emptyVar.length) {
        this.setState({ status: STATUS.VARIATION_CONTENT_NONE })
      } else if (hasError.subscriberContactValueError) {
        this.setState({ status: STATUS.VARIATION_EMAIL_NONE })
      } else if (hasError.InvalidsubscriberContactValue) {
        this.setState({ status: STATUS.INVALID_EMAIL })
      } else if (hasError.catalogError) {
        this.setState({ status: STATUS.CATALOGS_ERROR })
      } else if (hasError.productError) {
        this.setState({ status: STATUS.PRODUCTS_ERROR })
      }
    } else {
      if (product.attachments.some(a => a.imageHasUnsavedChanges)) {
        if (!confirm('Der er et eller flere billeder som ikke er blevet uploadet. Vil du gemme alligevel?')) {
          return
        }
      }
      product.categories = [].concat(product.products, product.tags)
      product.images = [].concat(product.attachments)
      product.video = product.video || ''
      if (!product.SupplierID) {
        delete product.SupplierID
      }
      if (product.dropshipFreightPrice === '') {
        product['dropshipFreightPrice'] = null
        this.setState({ product })
      }
      //console.log(product.catalogs)
      product.catalogs.map(e => {
        if (e.basisPrice === '' && e.discountPrice === '') {
          e.basisPrice = null
          e.discountPrice = null
        }
        this.setState({ product })
      })
      //console.log(product)
      this.setState({ status: STATUS.SAVING })
      POST('products/replace', product).then(responseJSON => {
        let nextState = {
          product: this.state.product
        }
        if (responseJSON) {
          if (responseJSON.error) {
            nextState.status = STATUS.PRODUCT_ERROR
          } else {
            nextState.product = responseJSON
            nextState.status = STATUS.PRODUCT_SUCCESS
          }
        }
        this.setState(nextState)
      })
    }
  }
  clearFormToast(status) {
    status ? this.setState({ status }) : this.setState({ status: STATUS.TAKING_INPUT })
  }
  deleteProduct(ProductID) {
    if (ProductID) {
      POST('products/remove', { ProductID: ProductID }).then(response => {
        let nextState = {}
        if (response.error) {
          nextState.status = STATUS.PRODUCT_DELETE
        } else {
          nextState.status = STATUS.PRODUCT_DELETE_ERROR
          this.getProducts()
        }
      })
    }
  }
  addAttachment(attachment) {
    let product = this.state.product
    product.attachments.push(attachment)
    this.setState({ product })
  }
  fileUploaded(idx, responseJSON) {
    let product = this.state.product
    product.attachments[idx] = responseJSON
    this.setState({ product })
  }
  videoFileUploaded(responseJSON) {
    let product = this.state.product
    product.video = responseJSON || ''
    this.setState({ product })
  }
  deleteImage(imageIdx = null) {
    if (imageIdx !== null) {
      let product = this.state.product
      product.attachments = product.attachments.filter((e, i) => {
        return i !== imageIdx
      })
      this.setState({ product })
    }
  }
  deleteVideo() {
    let product = this.state.product
    product.video = ''
    this.setState({ product })
  }

  getCatalogProducts(data) {
    let nextState = {
      products: [],
      status: STATUS.FETCHING_PRODUCTS,
      appliedFilter: true
    }
    nextState.filters = [{ filterID: 101, column: 'Catalog.moduleName', operator: 'EQUAL', value: data, name: data }]
    let predefinedFilters = this.state.predefinedFilters
    predefinedFilters.map(item => (item.selected = false))
    nextState.predefinedFilters = predefinedFilters
    this.setState(nextState)
    this.getProducts()
  }

  setFilter() {
    let filters = this.state.filters
    if (filters.length) {
      filters = this.state.filters
    } else {
      filters = []
      filters.push({ filterID: 101, column: 'Product.productStatus', operator: 'EQUAL', value: 'AVAILABLE', selected: true, name: 'Available' })
    }
    this.setState({ status: STATUS.FETCHING_PRODUCTS, filters, products: [], appliedFilter: false })
    this.resetPredefinedFilters()
    this.getProducts()
  }

  resetPredefinedFilters() {
    let predefinedFilters = this.state.predefinedFilters
    predefinedFilters = predefinedFilters.map(item => {
      item.selected = false
      return item
    })
    this.state.filters.forEach(filter => {
      predefinedFilters.forEach(rec => {
        if (rec.column === filter.column && rec.value === filter.value) {
          rec.selected = true
        }
      })
    })
    this.setState({ predefinedFilters })
  }
  preserveSearch(filterText) {
    this.setState({ filterText })
  }
  copyProduct(product) {
    POST('products/duplicate', { ProductID: product.ProductID }).then(response => {
      let nextState = {}
      if (response.error) {
        nextState.status = STATUS.COPY_PRODUCT_FAILED
      } else {
        nextState.status = STATUS.COPY_PRODUCT_SUCCESS
        this.getProducts()
        window.open(`/products/` + response.ProductID, '_blank')
      }
      this.setState(nextState)
    })
  }
}
