import styled from 'styled-components'
import { ProductActions } from '../../stores/productStore'
import { Button, Input, Tooltip } from 'reactstrap'
import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactCrop from 'react-image-crop'
import Proxies from 'Components/proxies'
import { useEffect } from 'react'

const PRODUCT_IMAGE_WIDTH = 740
const PRODUCT_IMAGE_HEIGHT = 1000

const IMAGE_CONTAINER_WIDTH = 364
const IMAGE_CONTAINER_HEIGHT = 492

const StyledAttachmentDiv = styled.div`
  margin-top: 5px;
`
const StyledImg = styled.img`
  width: ${IMAGE_CONTAINER_WIDTH}px;
  height: ${IMAGE_CONTAINER_HEIGHT}px;
  background: ${props =>
    !props.bgColor &&
    `-webkit-linear-gradient(45deg,rgba(0, 0, 0, 0.0980392) 25%,transparent 25%,transparent 75%,rgba(0, 0, 0, 0.0980392) 75%,rgba(0, 0, 0, 0.0980392) 0),
  -webkit-linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0,0,0,0.0980392)0),white`};
  background: ${props =>
    !props.bgColor &&
    `-moz-linear-gradient(45deg,rgba(0, 0, 0, 0.0980392) 25%,transparent 25%,transparent 75%,rgba(0, 0, 0, 0.0980392) 75%,rgba(0, 0, 0, 0.0980392) 0),
    -moz-linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392)0),white`};
  background: ${props =>
    !props.bgColor &&
    `linear-gradient(45deg,rgba(0, 0, 0, 0.0980392) 25%,transparent 25%,transparent 75%,rgba(0, 0, 0, 0.0980392) 75%,rgba(0, 0, 0, 0.0980392) 0),
    linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0),white`};
  background-repeat: repeat, repeat;
  background-position: 0px 0, 5px 5px;
  -webkit-transform-origin: 0 0 0;
  transform-origin: 0 0 0;
  -webkit-background-origin: padding-box, padding-box;
  background-origin: padding-box, padding-box;
  -webkit-background-clip: border-box, border-box;
  background-clip: border-box, border-box;
  -webkit-background-size: 10px 10px, 10px 10px;
  background-size: 10px 10px, 10px 10px;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-shadow: none;
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
  -webkit-transform: scaleX(1) scaleY(1) scaleZ(1);
  transform: scaleX(1) scaleY(1) scaleZ(1);
  background-color: ${props => props.bgColor};
`
const StyledAttachmentLabel = styled.span`
  line-height: 30px;
  align-self: center;
`
const StyledAttachmentInput = styled(Input)`
  width: 100px;
`
const StyledAttachmentCheckbox = styled(Input)`
  margin: 10px;
  position: relative;
`
const StyledFlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`

export default function ProductAttachment(props) {
  const defaultCrop = { unit: 'px', x: 0, y: 0, height: 491.8918918918919, width: IMAGE_CONTAINER_WIDTH }
  const [crop, setCrop] = useState(defaultCrop)
  const [completedCrop, setCompletedCrop] = useState(defaultCrop)
  const [originalImageBlob, setOriginalImageBlob] = useState(null)
  const [imageBlob, setImageBlob] = useState(null)
  const [imageBlobUrl, setImageBlobUrl] = useState(null)
  const [cropBlobUrl, setCropBlobUrl] = useState(null)
  const [bgRemovedBlob, setBgRemovedBlob] = useState(null)
  const [bgRemovedBlobUrl, setBgRemovedBlobUrl] = useState(null)
  const [isCropping, setIsCropping] = useState(false)
  const [isRemovingBG, setIsRemovingBG] = useState(false)
  const [bgColor, setBgColor] = useState(props.image.backgroundColor)
  const [imageSizeError, setImageSizeError] = useState('')
  const [showSizeErrorTooltip, setShowSizeErrorTooltip] = useState(false)
  const toggleTooltip = () => setShowSizeErrorTooltip(!showSizeErrorTooltip)
  const [addShadow, setAddShadow] = useState(false)

  const imageSrc = imageBlobUrl || props.image.imageOriginSource

  useEffect(() => {
    if (props.image.imageBlob) {
      setImageBlob(props.image.imageBlob)
      setOriginalImageBlob(props.image.imageBlob)
    } else {
      setImageBlob(null)
    }
  }, [props.image])

  useEffect(() => {
    if (imageBlob) {
      props.image.imageHasUnsavedChanges = true
      setImageBlobUrl(window.URL.createObjectURL(imageBlob))
    } else {
      props.image.imageHasUnsavedChanges = false
    }
  }, [imageBlob])

  useEffect(() => {
    if (bgRemovedBlob) {
      setBgRemovedBlobUrl(window.URL.createObjectURL(bgRemovedBlob))
    }
  }, [bgRemovedBlob])

  const checkImageSize = (imgWidth, imgHeight) => {
    if (imgWidth < PRODUCT_IMAGE_WIDTH - 10 || imgHeight < PRODUCT_IMAGE_HEIGHT - 10) {
      setImageSizeError(
        `Billedstørrelsen (${Math.trunc(imgWidth)}x${Math.trunc(
          imgHeight
        )}) er mindre end det forventede (${PRODUCT_IMAGE_WIDTH}x${PRODUCT_IMAGE_HEIGHT})`
      )
    } else {
      setImageSizeError('')
    }
  }

  const onImageLoad = async () => {
    const image = document.getElementById('attachment-image' + props.attachmentIndex)
    if (image && !imageSizeError) {
      checkImageSize(image.naturalWidth, image.naturalHeight)
    }
  }

  const onCropClicked = async () => {
    const image = document.getElementById('attachment-image' + props.attachmentIndex)
    const blob = await getImageBlobWithAddedBackground(image, props.image.imageName)
    setCropBlobUrl(window.URL.createObjectURL(blob))
    setIsCropping(true)
  }

  const onApproveCrop = () => {
    const image = document.getElementById('crop-image')
    getCroppedImg(image, completedCrop, props.image.imageName).then(result => {
      setImageBlob(result)
      setIsCropping(false)
    })
  }

  const onRemoveBackgroundClicked = async () => {
    setIsRemovingBG(true)

    const formData = new FormData()
    formData.append('size', 'auto')

    if (imageBlob) {
      formData.append('image_file', imageBlob)
    } else {
      formData.append('image_url', props.image.imageOriginSource)
    }

    if (addShadow) {
      formData.append('shadow_type', 'drop')
    }

    const response = await fetch('https://api.remove.bg/v1.0/removebg', {
      method: 'POST',
      headers: { 'X-Api-Key': 'bvAfYjmx7379QQD7XRSgFaQm' },
      body: formData
    })

    if (response.ok) {
      const fileBuffer = await response.arrayBuffer()
      const bytes = Buffer.from(fileBuffer)
      const blob = new Blob([bytes], { type: 'image/png' })
      setBgRemovedBlob(blob)
    } else {
      alert(`Kunne ikke fjerne baggrunden: ${response.statusText}`)
    }
  }

  const onApproveRemoveBG = () => {
    setImageBlob(bgRemovedBlob)
    setIsRemovingBG(false)
  }

  const onSave = () => {
    if (props.image.ImageID) {
      Proxies.updateImageFile(imageBlob, props.image.ImageID).then(responseJSON => {
        ProductActions.fileUploaded(props.attachmentIndex, {
          ...responseJSON,
          poolOrder: props.image.poolOrder,
          backgroundColor: props.image.backgroundColor,
          featureImage: props.image.featureImage
        })
      })
    } else {
      Proxies.uploadImage(imageBlob).then(responseJSON => {
        ProductActions.fileUploaded(props.attachmentIndex, {
          ...responseJSON,
          poolOrder: props.image.poolOrder,
          backgroundColor: props.image.backgroundColor,
          featureImage: props.image.featureImage
        })
      })
    }
  }

  const onRevertClicked = () => {
    if (originalImageBlob && confirm('Vil du slette dine ændringer til dette billede?')) {
      setImageBlob(originalImageBlob)
    }
  }

  const onBgColorChange = v => {
    setBgColor(v.replace(/'|"/g, ''))
  }

  const getImageBlobWithAddedBackground = (image, fileName) => {
    const canvas = document.createElement('canvas')
    canvas.width = image.naturalWidth * 2
    canvas.height = image.naturalHeight * 2
    const ctx = canvas.getContext('2d')

    ctx.drawImage(
      image,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
      image.naturalWidth / 2,
      image.naturalHeight / 2,
      image.naturalWidth,
      image.naturalHeight
    )

    const defaultCrop = {
      unit: 'px',
      x: IMAGE_CONTAINER_WIDTH / 4,
      y: IMAGE_CONTAINER_HEIGHT / 4,
      width: IMAGE_CONTAINER_WIDTH / 2,
      height: IMAGE_CONTAINER_HEIGHT / 2
    }
    setCrop(defaultCrop)
    setCompletedCrop(defaultCrop)

    return new Promise(resolve => {
      canvas.toBlob(
        blob => {
          blob.name = fileName
          resolve(blob)
        },
        'image/png',
        1
      )
    })
  }

  const getCroppedImg = (image, crop, fileName) => {
    const objectFit = getObjectFitContainSize(image.width, image.height, image.naturalWidth, image.naturalHeight)
    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / objectFit.width
    const scaleY = image.naturalHeight / objectFit.height
    canvas.width = PRODUCT_IMAGE_WIDTH
    canvas.height = PRODUCT_IMAGE_HEIGHT
    const ctx = canvas.getContext('2d')

    ctx.drawImage(
      image,
      (crop.x - objectFit.x) * scaleX,
      (crop.y - objectFit.y) * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      PRODUCT_IMAGE_WIDTH,
      PRODUCT_IMAGE_HEIGHT
    )

    checkImageSize(crop.width * scaleX, crop.height * scaleY)

    return new Promise(resolve => {
      canvas.toBlob(
        blob => {
          blob.name = fileName
          resolve(blob)
        },
        'image/png',
        1
      )
    })
  }

  return (
    <div key={props.attachmentIndex} className='mt-3 px-3'>
      {isCropping ? (
        <ReactCrop crop={crop} aspect={0.74} onChange={c => setCrop(c)} onComplete={c => setCompletedCrop(c)}>
          <StyledImg
            id='crop-image'
            src={cropBlobUrl || imageSrc}
            crossOrigin='Anonymous'
            style={{ objectFit: 'contain', backgroundColor: bgColor }}
          />
        </ReactCrop>
      ) : isRemovingBG ? (
        <StyledImg src={bgRemovedBlobUrl || imageSrc} crossOrigin='Anonymous' style={{ objectFit: 'cover' }} />
      ) : (
        <StyledImg
          id={'attachment-image' + props.attachmentIndex}
          src={imageSrc}
          alt={props.image.imageName}
          crossOrigin='Anonymous'
          style={{ objectFit: 'cover' }}
          bgColor={bgColor}
          onLoad={onImageLoad}
        />
      )}

      {isCropping ? (
        <StyledFlexDiv>
          <Button onClick={onApproveCrop}>Accept</Button>
          <Button onClick={() => setIsCropping(false)} color='danger'>
            Cancel
          </Button>
        </StyledFlexDiv>
      ) : isRemovingBG ? (
        <StyledFlexDiv>
          <Button onClick={onApproveRemoveBG}>Accept</Button>
          <Button onClick={() => setIsRemovingBG(false)} color='danger'>
            Cancel
          </Button>
        </StyledFlexDiv>
      ) : (
        <StyledAttachmentDiv>
          <StyledFlexDiv>
            <span style={{ alignContent: 'center' }}>
              Image ID: <strong>#{props.image.ImageID}</strong>
            </span>
            <span style={{ alignContent: 'center' }}>
              {imageSizeError && (
                <div>
                  <FontAwesomeIcon
                    id={'imageSizeWarning' + props.attachmentIndex}
                    icon='exclamation-triangle'
                    style={{ color: '#EEEE00' }}
                    scale='lg'
                  />
                  <Tooltip target={'imageSizeWarning' + props.attachmentIndex} toggle={toggleTooltip} isOpen={showSizeErrorTooltip}>
                    {imageSizeError}
                  </Tooltip>
                </div>
              )}
            </span>
            <span>
              {imageBlob ? (
                <Button onClick={onSave} style={{ width: 100 }}>
                  Upload <FontAwesomeIcon icon='upload' />
                </Button>
              ) : (
                <Button onClick={onSave} disabled style={{ width: 100, cursor: 'default' }}>
                  Uploaded
                </Button>
              )}
              {originalImageBlob && (
                <Button onClick={onRevertClicked} color='primary' style={{ marginLeft: 5 }}>
                  <FontAwesomeIcon icon='history' />
                </Button>
              )}
              <Button
                style={{ marginLeft: 5 }}
                color='danger'
                onClick={() => {
                  if (confirm('Vil du slette dette image?')) {
                    ProductActions.deleteImage(props.attachmentIndex)
                  }
                }}
              >
                <FontAwesomeIcon icon='trash' />
              </Button>
            </span>
          </StyledFlexDiv>
          <StyledFlexDiv>
            <span style={{ alignContent: 'center' }}>Tilføj skygge</span>
            <StyledAttachmentCheckbox checked={addShadow} type='checkbox' className='batch-header' onChange={() => setAddShadow(!addShadow)} />
            <Button onClick={onRemoveBackgroundClicked} color='primary'>
              Fjern baggrund <FontAwesomeIcon icon='image' />
            </Button>
            <Button onClick={onCropClicked} style={{ marginLeft: 5 }} color='primary'>
              Beskær <FontAwesomeIcon icon='crop' />
            </Button>
          </StyledFlexDiv>
          <div>
            <StyledFlexDiv>
              <StyledAttachmentLabel>Feature Image:</StyledAttachmentLabel>
              <StyledAttachmentCheckbox
                checked={props.image.featureImage}
                type='checkbox'
                name='featureImage'
                className='batch-header'
                value={props.image.featureImage}
                onChange={t => ProductActions.updateData(t, props.attachmentIndex, 'attachments')}
              />
            </StyledFlexDiv>
            <StyledFlexDiv>
              <StyledAttachmentLabel>Pool Order:</StyledAttachmentLabel>
              <StyledAttachmentInput
                type='number'
                name='poolOrder'
                value={props.image.poolOrder}
                onChange={t => ProductActions.updateData(t, props.attachmentIndex, 'attachments')}
              />
            </StyledFlexDiv>
            <StyledFlexDiv>
              <StyledAttachmentLabel>Background Color:</StyledAttachmentLabel>
              <StyledAttachmentInput
                type='text'
                name='backgroundColor'
                value={bgColor}
                onChange={v => onBgColorChange(v.target.value)}
                onBlur={t => ProductActions.updateData(t, props.attachmentIndex, 'attachments')}
              />
            </StyledFlexDiv>
          </div>
        </StyledAttachmentDiv>
      )}
    </div>
  )
}

// Gets the image size and offset when using object-fit contain
function getObjectFitContainSize(containerWidth, containerHeight, width, height) {
  var doRatio = width / height
  var cRatio = containerWidth / containerHeight
  var targetWidth = 0
  var targetHeight = 0

  if (doRatio > cRatio) {
    targetWidth = containerWidth
    targetHeight = targetWidth / doRatio
  } else {
    targetHeight = containerHeight
    targetWidth = targetHeight * doRatio
  }

  return {
    width: targetWidth,
    height: targetHeight,
    x: (containerWidth - targetWidth) / 2,
    y: (containerHeight - targetHeight) / 2
  }
}
